import { createRouter, createWebHashHistory } from 'vue-router'
// createWebHistory, // 待使用
// import ck from '../tools/cookie'
// import { getStore } from '../tools/storage.js'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index/Index')
  },
  // 测试页面
  // *********************
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/About')
  },
  {
    path: '/testdemo',
    name: 'testdemo',
    component: () => import('../views/about/TestKLine.vue')
  },
  // ***********************

  // 搜索内容
  {
    path: '/search',
    name: 'SearchMain',
    component: () => import('../components/search/SearchMain.vue')
  },
  // 咖啡社区
  {
    path: '/coffee',
    name: 'CoffeeCommunity',
    component: () => import('../views/CoffeeCommunity/index.vue')
  },
  {
    path: '/coffee/publications',
    name: 'Publications',
    component: () => import('../views/CoffeeCommunity/Publications.vue')
  },
  {
    path: '/coffee/communityarticle',
    name: 'CommunityArticle',
    component: () => import('../views/CoffeeCommunity/CommunityArticle.vue')
  },
  {
    path: '/coffee/user',
    name: 'coffeeUser',
    component: () => import('../views/CoffeeCommunity/CoffeeUser.vue')
  },
  {
    path: '/coffee/information',
    name: 'coffeeInformation',
    component: () => import('../views/CoffeeCommunity/informationIndex.vue')
  },
  {
    path: '/coffee/informationArticle',
    name: 'informationArticle',
    component: () => import('../views/CoffeeCommunity/informationArticle.vue')
  },
  // 地图页面
  {
    path: '/map',
    name: 'Mapshow',
    component: () => import('../views/mapshow/Map')
  },
  // 登录注册页
  {
    path: '/loginandregister',
    name: 'loginandregister',
    component: () => import('../views/loginandregister/LogRegMain.vue')
  },
  // 直播页面
  {
    path: '/live',
    name: 'LiveIndex',
    component: () => import('../views/live/LiveIndex.vue')
  },
  {
    path: '/live/detail',
    name: 'LiveDetail',
    component: () => import('../views/live/LiveDetail.vue')
  },
  {
    path: '/live/install',
    name: 'LiveInstall',
    component: () => import('../views/live/LiveInstall.vue')
  },
  {
    path: '/live/course',
    name: 'LiveCourse',
    component: () => import('../views/live/LiveCourse.vue')
  },
  // 预售页
  {
    path: '/reservation',
    name: 'reservation',
    component: () => import('../views/presell/reservationMain')
  },
  {
    path: '/reservation/varietiy_details',
    name: 'VarietiyDetails',
    component: () => import('../views/presell/VarietiyDetails.vue')
  },
  // 转
  {
    path: '/resell',
    name: 'ResellMain',
    component: () => import('../views/reseller/ResellMain.vue')
  },
  {
    path: '/resell/resell_details',
    name: 'ResellDetails',
    component: () => import('../views/reseller/ResellDetails.vue')
  },
  // 支付页面******************
  {
    path: '/buy',
    name: 'Accounts',
    component: () => import('../views/buy/Accouts.vue')
  },
  {
    path: '/samplebuy',
    name: 'sampleAccounts',
    component: () => import('../views/buy/sampleAccouts.vue')
  },
  {
    path: '/buyresell',
    name: 'AccoutsResell',
    component: () => import('../views/buy/AccoutsResell.vue')
  },
  {
    path: '/buy/settleaccounts',
    name: 'settleaccounts',
    component: () => import('../views/buy/settleAccounts.vue'),
    props: true
  },
  {
    path: '/buy/resaleaccounts',
    name: 'ResaleAccounts',
    component: () => import('../views/buy/ResaleAccounts.vue'),
    props: true
  },
  {
    path: '/buy/withholdingaccount',
    name: 'WithholdingAccount',
    component: () => import('../views/buy/WithholdingAccount.vue'),
    props: true
  },
  {
    path: '/buy/stockupaccounts',
    name: 'StockUpAccounts',
    component: () => import('../views/buy/StockUpAccounts.vue'),
    props: true
  },
  {
    path: '/paySuccess',
    name: 'PaySuccessMain',
    component: () => import('../views/paySuccess/PaySuccessMain.vue'),
    props: true
  },
  {
    path: '/paytopup',
    name: 'Paytopup',
    component: () => import('../views/paySuccess/Paytopup.vue'),
    props: true
  },
  // ******************

  // 竞拍路由
  {
    path: '/auction',
    name: 'Auction',
    component: () => import('../views/auction/AuctionMain.vue')
  },
  {
    path: '/auction/myauction',
    name: 'MyAuction',
    component: () => import('../views/auction/myauction/MyAuctionBody.vue')
  },
  // 我的竞拍详情
  {
    path: '/auction/myauction/acdetails',
    name: 'MyAuctionDetails',
    component: () => import('../views/auction/myauction/MyAuctionDetail.vue')
  },
  // 竞拍详情
  {
    path: '/auction/englishauction',
    name: 'EnglishAuction',
    component: () => import('../views/auction/myauction/EnglishAuction.vue')
  },
  // 现货路由
  {
    path: '/cashcommodity',
    name: 'cashcommodity',
    component: () => import('../views/cash/cashMain.vue')
  },
  {
    path: '/cashcommodity/varietiy_details',
    name: 'cashDetails',
    component: () => import('../views/cash/cashDetails.vue')
  },
  // 易货模块
  {
    path: '/barter',
    name: 'Barter',
    component: () => import('../views/barter/BarterMain.vue')
  },
  {
    path: '/barter/detail',
    name: 'BarterDetail',
    component: () => import('../views/barter/BarterDetail.vue')
  },
  // 专区模块
  {
    path: '/region',
    name: 'region',
    component: () => import('../views/region/Region.vue')
  },
  // 买家后台
  {
    path: '/personalcenter',
    name: 'personalcenter',
    component: () => import('../views/personalcenter/UserShow.vue'),
    children: [
      {
        path: 'myorder',
        name: 'myOrder',
        component: () => import('../views/personalcenter/myOrder.vue')
      }
    ]
  },
  {
    path: '/personalcenter/myorder/detail',
    name: 'OrderDetail',
    component: () => import('../views/personalcenter/OrderDetail.vue')
  },
  // 后台订单*******************
  {
    path: '/merchantworkbench',
    name: 'MerchantWorkbench',
    component: () => import('../views/business_shop/MerchantWorkbench.vue')
    // redirect: '/merchantworkbench/workbench',
    // children: [
    //   {
    //     path: 'workbench',
    //     name: 'WorkBench',
    //     component: () => import('../views/business_shop/workbench/WorkBenchMain.vue')
    //   },
    //   {
    //     path: 'posttrade',
    //     name: 'PostTrade',
    //     component: () => import('../views/business_shop/posttrade/PostTrade.vue')
    //   }
    // ]
  },
  {
    path: '/merchantworkbench/detail',
    name: 'MerchantOrderDetail',
    component: () => import('../views/business_shop/Order/OrderDetail.vue')
  },
  // 专区页面
  {
    path: '/special',
    name: 'SpecialMain',
    component: () => import('../views/special/SpecialMain.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   const token = getStore('token')
//   if (token ||
//    to.path === '/' ||
//    to.path === '/loginandregister' ||
//    to.path === '/live' ||
//    to.path === '/reservation' ||
//    to.path === '/map') {
//     next()
//   } else {
//     next('/loginandregister')
//   }
// })

export default router
