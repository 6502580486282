// import App from './App.vue'
// import { getStore } from '../tools/storage'
import router from '../router/index.js'
import axios from 'axios'
import Qs from 'qs'
import { ElMessage } from 'element-plus'
import ck from '../tools/cookie.js'
import { getStore, removeStore } from '../tools/storage.js'
import {
  url
} from './baseurl.js'
// var tokenck = ck.getCookie('token') || ''
var token = ck.getCookie('token') || getStore('token')
// console.log(token)
// console.log('aaaaa' + ck.getCookie('token'))
// Console.log(ordertoken+"头不知道怎么又没了")
const http = {}
// // import router from '../router/index';
// 创建axios实例
const service = axios.create({
  baseURL: url,
  timeout: 50000 // 请求超时时间
})
// console.log('ordertoken', ordertoken)
// 添加request拦截器
service.interceptors.request.use(
  config => {
    // console.log(config)
    // console.log(token)
    if (token) {
      config.headers.Authorization = `Bearer ${ck.getCookie('token') || getStore('token')}`
    // config.headers.setItem('Access-Control-Allow-Origin', 'http://localhost:8080')
    }
    return config
  },
  error => {
    // console.log(error)
    Promise.reject(error)
  }
)
// 添加respone拦截器
service.interceptors.response.use(
  response => {
    // console.log(response)
    if (response.data.code === 401 || response.data.code === 421 || response.status === 401) {
      // console.log('')
      console.log(response)
      removeStore('token')
      removeStore('usermessage')
      ck.clearCookie('token')
      ElMessage.warning({
        message: '授权失败，请重新登录'
      })
      router.push('/loginandregister')
      // router.push('/')
    }
    if (response.data.flag || response.data.code === 200 || response.data.isSucc === 1) {
      // console.log('uuuuuuuuu------------')
      return response
    }
    // 直播接口传值
    if (response.status === 200 || response.statusText === 'OK') {
      return response
    }

    if (!response.data.flag || response.data.code === 500 || response.data.code === 503) {
      ElMessage.error({
        message: '服务器异常'
      })
      return response
    }

    ElMessage({
      message: response.data.message || response.data.msg,
      type: 'error'
    })
    // Message.error(response.data.message);
  },
  error => {
    // console.log(error)
    // // console.log(error.message)
    // console.log(error.code)
    const str = error.message
    // console.log(str.indexOf('code'))
    if (str.indexOf('status code 401') !== -1) {
      removeStore('token')
      removeStore('usermessage')
      ck.clearCookie('token')
      ElMessage.warning({
        message: '授权失败，请重新登录'
      })
      router.push('/loginandregister')
      return Promise.reject(error.response)
    }
    ElMessage.error({
      message: '服务器异常'
    })
    // // Message.error(error.message);

    // return Promise.reject(error.response)
    return Promise.reject(error.response)
  }
)

http.get = function (url, params) {
  // console.log(1111)
  const ordertoken = `Bearer ${ck.getCookie('token') || getStore('token')}`
  // const token = getStore('token') || ''
  // console.log(params)
  // Params.t = new Date().getTime(); //get方法加一个时间参数,解决ie下可能缓存问题.
  return service({
    url,
    method: 'get',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: ordertoken
      // token: token
    },
    params
  })
}

// 封装post请求
http.post = function (url, data = {}, typestatus, filestatus) {
  // 默认配置
  let sendObject = ''
  const status = typestatus || false
  // const token = getStore('token') || ''
  const ordertoken = `Bearer ${ck.getCookie('token') || getStore('token')}`
  // console.log('post',ordertoken)
  /*
   * Console.log('status:', status);
   * console.log(ordertoken)
   */
  if (filestatus) {
    sendObject = {
      url,
      method: 'post',
      headers: {
        //  "content-type": "application/x-www-form-urlencoded",
        Authorization: ordertoken
        // token: token
      },
      data
    }
    sendObject.data = data
    return service(sendObject)
  }
  if (status) {
    sendObject = {
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        //  "content-type": "application/x-www-form-urlencoded",
        Authorization: ordertoken
        // token: token
      },
      data
    }
    sendObject.data = data
    // console.log('true', data)
  } else {
    sendObject = {
      url,
      method: 'post',
      headers: {
        // "Content-Type": "application/json",
        'content-type': 'application/x-www-form-urlencoded',
        Authorization: ordertoken
        // token: token
      },
      data
    }
    sendObject.data = Qs.stringify(data)
    // console.log('false', data)
  }

  return service(sendObject)
}

// 不要忘记export
export {
  service
}
export default http
