/**
 * @description websocket类
 * @
 * */
// import store from '../store/index.js'
// import { connection } from '@/common/initialchat.js'
import { wsduankou, wsurl, wsscheme } from '@/api/baseurl.js'
import { getStore } from '../tools/storage'
var MessageInfoType = {
  MSG_PING: '0',
  MSG_READY: '1',
  MSG_MESSAGE: '2'
}
export default class {
  constructor (url, token) {
    this.url = url
    console.log(url)
    const tokens = getStore('token') || ''

    this.token = token || tokens
    this.sendinterval = ''
    this.is_open_socket = false // 避免重复连接
    // 心跳检测
    this.timeout = 50000// 多少秒执行检测
    this.heartbeatInterval = null // 检测服务器端是否还活着
    this.reconnectTimeOut = null // 重连之后多久再次重连
    clearTimeout(this.reconnectTimeOut)
    clearInterval(this.heartbeatInterval)
    // this.closeSocket()
    try {
      return this.connectSocketInit()
    } catch (e) {
      console.log('catch')
      this.is_open_socket = false
      this.reconnect()
    }
  }

  // 进入这个页面的时候创建websocket连接
  connectSocketInit () {
    console.log('websocket连接', wsscheme + this.url + '?token=' + this.token)
    // this.socketTask = uni.connectSocket({
    //   url: `${wsscheme}` + this.url + '?token=' + this.token,
    //   success: () => {
    //     // console.log("正准备建立websocket中...");
    //     // 返回实例
    //     return this.socketTask
    //   }
    // })
    this.socketTask = new WebSocket(`${wsscheme}` + this.url + '?token=' + this.token)

    this.socketTask.onopen = (res) => {
      // console.log("WebSocket连接正常！");
      // this.sendWsRequest(this.historyData)

      clearTimeout(this.reconnectTimeOut)
      clearInterval(this.heartbeatInterval)
      this.is_open_socket = true
      this.ready()
      // this.acceptSocketMessages()
      // 注：只有连接正常打开中 ，才能正常收到消息
      this.socketTask.onmessage = (res) => {
        console.log('正常收到消息了')
        // console.log(res)
        // store.commit('solveMessage',res)
      }
    }
    // 监听连接失败，这里代码我注释掉的原因是因为如果服务器关闭后，和下面的onclose方法一起发起重连操作，这样会导致重复连接
    this.socketTask.onerror = (res) => {
      // console.log('WebSocket连接打开失败，请检查！');
      this.is_open_socket = false
      // this.reconnect();
    }
    // 这里仅是事件监听【如果socket关闭了会执行】
    this.socketTask.onclose = () => {
      // console.log("已经被关闭了")

      this.reconnect()
    }
  }

  sendWsRequest (options) {
    switch (this.socketTask.readyState) {
      case 0:
        this.checkSendMessage(JSON.stringify(options))
        break
      case 1:
        this.socketTask.send(JSON.stringify(options))
        break
      case 2:
        console.log('ws关闭状态')
        break
      case 3:
        this.connectSocketInit()
        break
      default:
        console.log('ws未知错误')
    }
  }

  // 发送消息
  send (value) {
    // const that = this
    // 注：只有连接正常打开中 ，才能正常成功发送消息
    try {
      console.log('发送成功')
      value = JSON.stringify(value)
      this.sendSocketMessage(value)
      // that.start()
    } catch (error) {
      console.log('发送失败')
    }
    // uni.sendSocketMessage({
    //   data: JSON.stringify(value),
    //   success: function (res) {
    //     console.log(res)
    //     console.log('发送成功')
    //   },
    //   fail: function (err) {
    //     console.log(err)
    //     uni.showToast({
    //       title: '发送失败',
    //       icon: 'none'
    //     })
    //     that.reconnect()
    //   }
    // })
    // this.socketTask.send({
    // data: value,
    // async success() {
    // console.log("消息发送成功");

    // },
    // });
  }

  // 接受消息
  acceptSocketMessages (callback) {
    this.socketTask.onmessage(callback)
    console.log(callback)
    // this.socketTask.onMessage((res) => {
    // console.log('正常收到消息了')
    // console.log(res.data)
    // });
  }

  // 发送就绪
  ready () {
    // console.log('调用ready')
    const code = MessageInfoType.MSG_READY
    const codes = `{"code":${code}}`
    const that = this
    try {
      this.sendSocketMessage(codes)
      that.start()
    } catch (error) {
      console.log('发送就绪失败')
    }
  }

  // 开启心跳检测
  start () {
    // console.log('心跳检测')
    this.heartbeatIntervalss()
  }

  heartbeatIntervalss () {
    const code = MessageInfoType.MSG_PING
    const that = this
    this.heartbeatInterval = setInterval(() => {
      try {
        this.sendSocketMessage(`{"code":${code}}`
          // success: function (res) {
          //   // console.log('发送心跳成功')
          // },
          // fail: function (err) {
          //   // console.log('发送心跳失败')
          // }
        )
      } catch (error) {
        that.reconnect()
      }
    }, this.timeout)
  }

  /**
      关闭连接
      * */
  closeSocket () {
    console.log('关闭链接0------')
    this.is_open_socket = true
    this.socketTask.close()
  }

  // 重新连接
  reconnect () {
    // 停止发送心跳
    console.log('停止')
    clearInterval(this.heartbeatInterval)
    // 如果不是人为关闭的话，进行重连
    if (!this.is_open_socket) {
      this.reconnectTimeOut = setTimeout(() => {
        this.connectSocketInit()
      }, 2000)
    }
  }

  /**
     * 创建实列
     * */
  newwebsocket () {
    // new WebSocket(`${wsurl}${wsduankou}`, getStore('token'))
  }

  /**
     * @description websocket连接
     * */
  connectSocket () {
    // const that = this
    // console.log('websocket连接', 'wss://' + this.url + '?token=' + this.token)
    // uni.connectSocket({
    //   url: 'wss://' + this.url + '?token=' + this.token,
    //   success (data) {
    //     console.log(data)
    //     console.log('websocket创建成功-----')
    //   }
    // })
    this.connectSocketInit()
  }

  /**
     * @description 消息载体对象
     * @param {String} code
      * @param {Object} msg
     * */
  setMsgObj (code, msg) {
    this.msgObj = { code: code, mess: msg }
  }

  getMsgObj () {
    return this.msgObj || ''
  }

  /**
     * @description 消息对象
     * @param {String} mineId
      * @param {String} toId
      * @param {String} content
     * */
  setMsg (mineId, toId, content) {
    this.msg = {
      mineId: mineId,
      toId: toId,
      content: content
    }
  }

  getMsg () {
    return this.msg
  }

  /**
     * @description 本地消息数组
     * */
  setMsgList (msg) {
    this.msgList.push(msg)
  }

  getMsgList () {
    return this.msgList
  }

  /**
     * @description 接收的消息对象
     * */
  setServiceMsg (serviceMsg) {
    this.serviceMsg = serviceMsg
  }

  getServiceMsg () {
    return this.serviceMsg
  }

  /**
     * @description websocket打开状态
     * @return {boolean}
     * */
  isSocketOpen () {
    return new Promise((resolve, reject) => {
      this.socketTask.onmessage = () => {
        resolve(true)
      }
      this.socketTask.onerror = () => {
        return reject
      }
    })
  }

  /**
     * @description 发送消息
     * @param {MsgObj} msgObj
     * */
  sendSocketMessage (sendMsg) {
    // uni.sendSocketMessage(sendMsg)
    this.socketTask.send(sendMsg)
  }

  /**
     * @description 接收消息
     * @param {MsgObj} msgObj
     * */
  acceptSocketMessage (callback) {
    // this.socketTask.onmessage(callback)
    // console.log(callback)

    this.socketTask.onmessage = (res) => {
      // console.log('正常收到消息了')
      // console.log(res)
      // return res
      callback(res)
      // store.commit('solveMessage',res)
    }
  }

  /**
     * 心跳检测
     * */
  heartbeat () {
    console.log('心跳检测')
    this.hearbeatchecks()
  }

  hearbeatchecks () {
    const code = MessageInfoType.MSG_PING
    this.sendinterval = setInterval(() => {
      this.sendSocketMessage(`{"code":${code}}`)
    }, 50000)
  }

  cleartime () {
    clearInterval(this.sendinterval)
  }
}
