import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus, { ElMessage, ElLoading } from 'element-plus'
import 'babel-polyfill'
import 'element-plus/dist/index.css'
import 'dayjs/locale/zh-cn' // 中文
import elzhcn from 'element-plus/lib/locale/lang/zh-cn' // 中文
import elenus from 'element-plus/lib/locale/lang/en' // 英文
import * as ElIconModules from '@element-plus/icons-vue'

import { createI18n } from 'vue-i18n'
import md5 from 'js-md5'
import 'normalize.css'
import './style/base.css'
import '../src/style/vivify.min.css'
import axios from 'axios'
import scroll from 'vue-seamless-scroll/src'
import infiniteScroll from 'vue-infinite-scroll'
import VueClipBoard from 'vue-clipboard2'
import IconSvg from './components/icon-components.vue'

// webSocket
import WsRequest from '@/common/WebSocketClass.js'
import { getStore } from './tools/storage'
import { wsduankou, wsurl } from '@/api/baseurl.js'

// 全局方法
// console.log(elenus)

const app = createApp(App)

const lang = getStore('exp2c_language') != null ? getStore('exp2c_language') : 'zh-CN' // 判断是否有缓存
const elLangStatus = getStore('exp2c_language') === 'en-US' ? elenus : elzhcn // 判断是否有缓存
const localeData = {
  legacy: false,
  locale: lang,
  messages: {
    'zh-CN': require('./lang/zh'), // 通过require引入中文语言包
    'en-US': require('./lang/en') // 通过require引入英文语言包
  }
}
const i18n = createI18n(localeData)
const tokens = getStore('token') || ''
if (tokens) {
  // console.log('开始连接websocket')
  const WebSocket = new WsRequest(`${wsurl}${wsduankou}`, tokens)
  app.config.globalProperties.$WebSocket = WebSocket
}

app.component('icon-svg', IconSvg)

// 全局指令
app.directive('active', (element, binding) => {
  // console.log(element)
  // console.log(binding)
  element.classList.add('active')
})
for (const iconName in ElIconModules) {
  if (Reflect.has(ElIconModules, iconName)) {
    const item = ElIconModules[iconName]
    app.component(iconName, item)
  }
}

// 全局挂载
app.config.globalProperties.$store = store
app.config.globalProperties.$axios = axios
app.config.globalProperties.$loading = ElLoading
app.config.globalProperties.$md5 = md5
// app.config.globalProperties.$t = i18n
app.config.globalProperties.$aliUrl = 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/'
// app.config.globalProperties.$mybus = new Mitt()
// app.config.globalProperties.$Message = message
// app.use(IconSvg)
app.use(i18n)
app.use(infiniteScroll)
app.use(VueClipBoard)
app.use(ElementPlus, { locale: elLangStatus })
app.use(ElMessage)
app.use(ElLoading)
app.use(store)
app.use(router)
app.use(scroll)
// app.use(VueCookies)
app.mount('#app')

// App.config.globalProperties.$echarts = echarts
