import http from '../api/http.js'

// 创建房间号
export const addchatGroup = (data) => {
  return http.post('mall-chat/api/chat/group/create', data)
}

// 根据房间号查询信息
export const getchatoldmes = (data) => {
  return http.get('mall-chat/api/chat/message/getHistoryPage', data)
}

// 卖家客服列表
export const sellerlist = (data) => {
  return http.get('mall-chat/api/chat/merchantCustomerService/list', data)
}

// 买家消息列表
export const getcustomermessagelist = (data) => {
  return http.post('mall-chat/api/chat/message/getSpeakList', data)
}

// 清除未读消息
export const clearUnreadNunber = (data) => {
  return http.post('mall-chat/api/chat/message/markRead', data)
}
// 删除会话
export const deleteSpeakList = (data) => {
  return http.post('mall-chat/api/chat/message/deleteSpeak', data)
}

// 平台客服列表
export const merchantsList = (data) => {
  return http.get('mall-chat/api/chat/platformCustomerService/list', data)
}
// 添加平台客服到当前聊天窗口
export const addplatformCustomer = (data) => {
  return http.post('mall-chat/api/chat/platformCustomerService/add', data)
}
// 查寻群组用户
export const getchatUserList = (data) => {
  return http.get('mall-chat/api/chat/user/chatUserList', data)
}
// 客服退出聊天群
export const serviceExit = (data) => {
  return http.post('mall-chat/api/chat/platformCustomerService/exit', data)
}
// 提交评价
export const subChatEvaluation = (data) => {
  return http.post('mall-chat/api/chat/appraise/save', data, true)
}
// 查聊天记录
export const looklist = (reviceuserid, cuserid, data) => {
  return http.post(`mall-chat/chat/lkuschatmsg/${reviceuserid}`, data)
}
// 聊天jia好友
export const addfriend = (fuserid, cuserid, data) => {
  return http.post(`chat/adduser/${fuserid}`, data)
}
