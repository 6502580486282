
import store from '@/store/index.js'
import { getStore, setStore } from '../tools/storage'

// 缓存存入数据
export function setStoreChatList (name, data) {
  setStore(name, JSON.stringify(data))
}
// 取出缓存的数组列表
export function getStoreChatList (name) {
  const datas = getStore(name)
  if (!datas) {
    return []
  }
  return JSON.parse(datas)
}

// 取出存入的买家全部未读数
export function getStoreChat (name) {
  const datas = getStore(name)
  if (!datas) {
    return 0
  }
  store.state.allSpeakUnread = datas
  return datas
}
